import React from 'react';

import { css } from 'emotion';

const styled = css`
  cursor: pointer;
  background-color: #55fcb9;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 10px 20px;
  height: auto;
  transition: background-color 0.2s;

  :hover {
    background-color: #53f4b1;
  }

  :disabled {
    background-color: #cacaca;
    cursor: not-allowed;
  }
`;

const text = css`
  font-size: 20px;
  text-align: center;
  color: #4b3886;
  font-family: 'Fredoka One', cursive;

  /* Telefones em paisagem a tablet em retrato */
  @media (max-width: 780px) {
    font-size: 20px;
  }

  /* Tablet em retrato a paisagem e desktop */
  @media (min-width: 768px) and (max-width: 979px) {
    font-size: 20px;
  }

  /* Desktop grande */
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`;

export default function GameButton(props) {
  let width = '100%';
  if (props.notFull) {
    width = 'auto';
  }
  return (
    <button
      type="button"
      className={styled}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{ width }}
    >
      {props.label && <span className={text}>{props.label}</span>}
    </button>
  );
}
