import React from "react";

import Head from "next/head";
import { useRouter } from "next/router";

import { css } from "emotion";

import Error404 from "@/components/404";
import GameButton from "@/components/game-button";

import AuthService from "../services/auth";

const divStyles = css`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    font-size: 20px;
    font-family: "Fredoka One", cursive;
    color: #fff;
  }

  .button {
    margin-top: 100px;
    width: 200px;
  }
`;

export default function Page404() {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>
          404 - Página não encontrada | Gamefik Escolas no level máximo.
        </title>
      </Head>
      <div className={divStyles}>
        <Error404 />
        <h1 className="text">Página não encontrada...</h1>
        <div className="button">
          <GameButton
            label="Voltar"
            onClick={() => {
              router.push("/");
            }}
          />
        </div>
      </div>
    </>
  );
}
