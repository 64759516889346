import React from 'react';

import { css } from 'emotion';
import Lottie from 'react-lottie';

import error404Lottie from './error404.json';

export default function Error404() {
  return (
    <div
      className={css`
        background: none;
        max-width: 600px;
      `}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: error404Lottie,
        }}
      />
    </div>
  );
}
